<template>
   <div>
      <!-- List headers -->
      <v-card>
         <v-card-title>
            {{ translateKey("listItemsTab.listHeaders", translations) }}
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field
                     label="Header"
                     v-model="newListHeader.name"
                     @blur="onNameBlur"
                     @keyup.enter="onNameConfirmed"
                     ref="nameField"
                  />
               </v-col>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field
                     label="Identifier"
                     v-model="newListHeader.identifier"
                     append-icon="mdi-refresh"
                     @click:append="regenerateIdentifier"
                     @keyup.enter="onAddNewListHeaderClick"
                     ref="identifierField"
                  />
               </v-col>
               <v-col md="4" xs="12" sm="4" class="pt-0 pb-0" align-self="center">
                  <v-btn @click="onAddNewListHeaderClick" elevation="2">
                     <v-icon color="success">mdi-plus</v-icon>
                     <span>Add</span>
                  </v-btn>
               </v-col>
            </v-row>
            <v-row v-for="(listHeader, index) in listModel.headers" :key="index">
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field label="Header" v-model="listHeader.name" readonly />
               </v-col>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-text-field label="Identifier" v-model="listHeader.identifier" readonly />
               </v-col>
               <v-col xs="12" md="4" class="pt-0 pb-0" align-self="center">
                  <v-btn @click="onRemoveListHeaderClick(listHeader)" color="error" icon>
                     <v-icon>mdi-delete</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>

      <!-- List items -->
      <v-card>
         <v-card-title>
            <v-row>
               <v-col>
                  <span class="definition-padding-right">
                     {{ translateKey("listItemsTab.listItemsLabel", translations) }}
                  </span>
                  <v-btn @click="onAddNewListItemClick">
                     <v-icon color="success">mdi-plus</v-icon>
                     {{ translateKey("button.add", translations) }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col>
                  <v-data-table :headers="tableHeaders" :items="listItems">
                     <template v-for="tableHeader in tableHeaders" v-slot:[`item.${tableHeader.value}`]="{ item }">
                        <v-text-field
                           :key="tableHeader.value"
                           :value="item.columns?.get(tableHeader.value)"
                           @input="updateKeyValue(item, tableHeader.value, $event)"
                        ></v-text-field>
                     </template>
                     <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon small>
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>
                  </v-data-table>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { List, ListHeader, ListItem, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import Sortable from "sortablejs";
import ValidationRules from "@models/shared/ValidationRules";
import DataModelUtils from "@utils/DataModelUtils";
import { on } from "events";

@Component({
   name: "ListDetailTabMulticolumnItems",
   directives: {
      sortableDataTable: {
         bind(el, binding, vnode) {
            const options = {
               animation: 150,
               onUpdate: function (event) {
                  vnode.child.$emit("sorted", event);
               },
               filter: ".drag-filter",
            };
            Sortable.create(el.getElementsByTagName("tbody")[0], options);
         },
      },
   },
})
export default class ListDetailTabMulticolumnItems extends ComponentBase {
   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   listModel: List;

   @Prop({ default: true })
   readonly: boolean;

   newListHeader: ListHeader = new ListHeader();

   tableHeaders: any[] = [{ text: "Actions", value: "actions" }];

   listItems: ListItem[] = [];

   mounted() {
      if (!this.listModel?.headers) {
         this.listModel.headers = [];
      }

      this.updateTableHeaders();

      if (this.listModel.items) {
         this.listModel.items.forEach((item) => {
            let newListItem = new ListItem();
            newListItem.columns = new Map<string, string>();
            this.listModel.headers.forEach((header) => {
               newListItem.columns.set(header.identifier, item.columns[header.identifier]);
            });
            this.listItems.push(newListItem);
         });
      }
   }

   onNameBlur() {
      if (!this.newListHeader.name) return;

      if (!this.newListHeader.identifier) {
         this.regenerateIdentifier();
      }
   }

   onNameConfirmed() {
      this.$refs.identifierField?.focus();
   }

   onAddNewListHeaderClick() {
      if (this.listModel.headers?.filter((x) => x.identifier == this.newListHeader.identifier).length > 0) {
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: this.translateKey("listItemsTab.identifierAlreadyUsed", this.translations),
         });
         return;
      }
      this.addNewColumnHeader(this.newListHeader);
      this.newListHeader = new ListHeader();
      this.updateTableHeaders();
      this.$refs.nameField?.focus();
   }

   regenerateIdentifier() {
      this.newListHeader.identifier = DataModelUtils.generateNodeIdentifier(this.newListHeader.name) ?? "";
      this.$forceUpdate();
   }

   onRemoveListHeaderClick(header: any) {
      this.listModel.headers!.splice(this.listModel.headers!.indexOf(header), 1);
      this.updateTableHeaders();
   }

   addNewColumnHeader(header: any) {
      this.listModel.headers!.push(header);
   }

   updateTableHeaders() {
      this.tableHeaders = [];
      this.listModel.headers!.forEach((header) => {
         this.tableHeaders.push({ text: header.name, value: header.identifier });
      });
      this.tableHeaders.push({ text: "Actions", value: "actions" });
   }

   onAddNewListItemClick() {
      let newListItem = new ListItem();
      newListItem.columns = new Map<string, string>();

      this.listModel?.headers?.forEach((header) => {
         newListItem.columns.set(header.identifier, "");
      });

      this.listItems.push(newListItem);
      this.emitListItems();
   }

   updateKeyValue(item: ListItem, key: string, value: string) {
      item.columns.set(key, value);
      this.emitListItems();
   }

   emitListItems() {
      this.$emit("list-items-updated", this.listItems);
   }
}
</script>

<style scoped></style>
